.page3-container {
    padding: 50px;
    font-family: Arial, sans-serif;
    background: linear-gradient(to right, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    color: #fff;
  }
  
  .header-title {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .header-title h1 {
    font-family: "Brush Script MT", cursive;
    font-size: 3rem;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  /* Feedback Section */
  .feedback-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 20px;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10px);
  }
  
  .feedback-slider {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  
  /* Feedback Card */
  .feedback-card {
    padding: 20px;
    border-radius: 15px;
    background: linear-gradient(to bottom right, #ffffff, #f3f4f6);
    border: none;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    overflow: hidden;
  }
  
  .feedback-card:hover {
    transform: translateY(-8px) scale(1.05);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.25);
    background: linear-gradient(to bottom right, #e0f7fa, #ffffff);
  }
  
  .feedback-card::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.3) 10%, rgba(255, 255, 255, 0) 60%);
    z-index: -1;
    opacity: 0;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  }
  
  .feedback-card:hover::before {
    opacity: 1;
    transform: scale(1.5);
  }
  
  /* Feedback Header */
  .feedback-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .client-name {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0;
    color: #333;
  }
  
  .client-subtitle {
    font-size: 0.9rem;
    color: #555;
  }
  
  .stars {
    display: flex;
    gap: 5px;
    color: #ffd700;
    font-size: 1.2rem;
  }
  
  /* Feedback Body */
  .feedback {
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
    margin: 10px 0;
    text-align: justify;
  }
  
  /* Feedback Footer */
  .feedback-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
  }
  
  .company-logo {
    width: 100px;
    height: auto;
    opacity: 0.8;
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  .company-logo:hover {
    opacity: 1;
    transform: scale(1.1);
  }
  
  .feedback-date {
    font-size: 0.8rem;
    color: #777;
    text-align: right;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .feedback-section {
      padding: 20px;
    }
  
    .feedback-card {
      padding: 15px;
    }
  
    .client-name {
      font-size: 1rem;
    }
  
    .feedback {
      font-size: 0.9rem;
    }
  
    .stars {
      font-size: 1rem;
    }
  }
  