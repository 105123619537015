/* Page7.css */

/* Base container for the video background */
.background-video-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

/* Video styling to ensure it covers the background */
.background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1; /* Ensures the video stays behind the content */
}

/* Overlay to center the text */
.overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7);
    width: 100%; /* Ensures the content spans the full width */
}

/* Main title styling */
.main-title {
    font-size: 5rem;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
    letter-spacing: 3px;
    font-family: 'Dancing Script', cursive; /* Cursive font */
    color: #fff;
    transition: all 0.3s ease;
}

/* Hover effects for the main title */
.main-title:hover {
    text-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4), -4px -4px 10px rgba(0, 0, 0, 0.4); /* Stronger shadow on hover */
    transform: scale(1.05); /* Slight scale effect on hover */
}

/* Subtitle styling */
.subtitle {
    font-size: 1.5rem;
    font-weight: 300;
    margin-top: 20px;
    letter-spacing: 1px;
    line-height: 1.6;
    font-family: 'Pacifico', cursive; /* Cursive font */
    color: #8b8a8a; /* A nice dark color for contrast */
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    transition: all 0.3s ease; /* Smooth transition for hover effect */
}

/* Hover effects for the subtitle */
.subtitle:hover {
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3); /* Slightly stronger shadow on hover */
    transform: scale(1.03); /* Slight scale effect on hover */
}

/* Responsive design adjustments */

/* For devices with width smaller than 1200px (tablets and smaller desktops) */
@media (max-width: 1200px) {
    .main-title {
        font-size: 4rem; /* Adjust size */
        letter-spacing: 2px; /* Slightly reduce letter-spacing */
    }

    .subtitle {
        font-size: 1.25rem; /* Adjust subtitle font size */
    }
}

/* For devices with width smaller than 992px (large tablets) */
@media (max-width: 992px) {
    .main-title {
        font-size: 3.5rem; /* Smaller font size */
        letter-spacing: 1.5px; /* Reduce letter-spacing further */
    }

    .subtitle {
        font-size: 1.125rem; /* Slightly smaller subtitle */
    }

    .overlay {
        padding: 10px; /* Add padding for better spacing */
    }
}

/* For devices with width smaller than 768px (mobile devices) */
@media (max-width: 768px) {
    .main-title {
        font-size: 2.5rem; /* Significantly reduce font size */
        letter-spacing: 1px; /* Reduce letter-spacing */
    }

    .subtitle {
        font-size: 1rem; /* Adjust subtitle font size */
    }

    .overlay {
        padding: 10px 20px; /* Ensure enough padding for readability */
    }
}

/* For devices with width smaller than 480px (extra small devices) */
@media (max-width: 480px) {
    .main-title {
        font-size: 2rem; /* Adjust for very small screens */
    }

    .subtitle {
        font-size: 0.9rem; /* Adjust subtitle size */
    }
}

/* For zoom responsiveness */
@media (min-width: 320px) and (max-width: 1800px) {
    .background-video {
        object-position: center center; /* Ensure the video is properly centered on all devices */
    }

    /* Optional: Adjust text size for zoom */
    .main-title, .subtitle {
        transition: font-size 0.3s ease; /* Smooth transition */
    }

    .main-title:hover, .subtitle:hover {
        transform: scale(1); /* Prevent scaling on zoom for better readability */
    }
}
