/* Base styles for the gallery */
body {
  font-family: "Arial", sans-serif;
  margin: 0;
  padding: 0;
}

.gallery-container {
  max-width: 1800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.gallery-title {
  font-size: 2.5rem;
  color: #28a745;
  margin-bottom: 30px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

/* Individual gallery item */
.gallery-item {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.gallery-item:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.3);
}

/* Image styles */
.gallery-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

/* Overlay styles */
.gallery-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 15px;
}

.gallery-item:hover .gallery-overlay {
  opacity: 1;
}

/* Text inside overlay */
.gallery-text {
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}
