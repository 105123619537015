/* Container */
.page8-container {
  padding: 20px;
  background: linear-gradient(120deg, #e0f7fa, #ffffff);
  font-family: "Roboto", Arial, sans-serif;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh; /* Full viewport height */
  text-align: center;
}

/* Content Layout */
.page8-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

/* Text Section */
.page8-text {
  width: 100%;
  padding: 0 16px;
}

.page8-highlight {
  color: #ff6f61;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1px;
}

.page8-title {
  font-size: 30px;
  font-weight: bold;
  margin: 12px 0;
  color: #2b2d42;
}

.page8-description {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 16px;
  color: #555;
}

/* Button Styles */
.page8-button {
  display: inline-block;
  padding: 12px 24px;
  background: linear-gradient(90deg, #ff6f61, #e63946);
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.page8-button:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(230, 57, 70, 0.5);
}

.page8-button .arrow {
  margin-left: 8px;
}

/* Image Section */
.page8-image img {
  max-width: 100%;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  margin: 16px 0;
  transition: transform 0.3s ease;
}

.page8-image img:hover {
  transform: scale(1.02);
}

/* Statistics Section */
.page8-stats {
  display: flex;
  margin-top: 5cap;
  justify-content: center;
  gap: 100px;
  background: #eef5f8;
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.stat-box {
  text-align: center;
  flex: 1 1 450px;
  margin: 10px;
}

.stat-number {
  font-size: 24px;
  font-weight: bold;
  color: #007b5e;
}

.stat-label {
  font-size: 14px;
  color: #555;
  margin-top: 4px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .page8-content {
    padding: 16px;
  }

  .page8-title {
    font-size: 24px;
  }

  .page8-description {
    font-size: 14px;
  }

  .page8-button {
    font-size: 14px;
    padding: 10px 20px;
  }

  .stat-number {
    font-size: 20px;
  }

  .stat-label {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .page8-title {
    font-size: 22px;
  }

  .page8-description {
    font-size: 13px;
  }

  .page8-button {
    font-size: 13px;
    padding: 8px 16px;
  }
}
