/* General News Container Styling */
.news-container {
    margin-top: 20%;
    width: 100%;
    max-width: 1800px;
    margin: 0 auto;
    padding: 40px;
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* Title Styling */
.news-title {
    font-family: "Brush Script MT", cursive;
    font-size: 3rem;
    color: #ee7752;
    margin-bottom: 40px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    transition: color 0.3s ease;
}

.news-title:hover {
    color: #23a6d5;
}

/* Grid Layout for News Cards */
.news-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(430px, 1fr));
    gap: 80px;
    width: 100%;
    padding: 0 20px;
}

/* Individual News Card Styling */
.news-card {
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    border: 2px solid #ee7752;
}

.news-card:hover {
    transform: translateY(-10px) scale(1.03);
    box-shadow: 0 18px 36px rgba(0, 0, 0, 0.2);
}

.news-card:focus {
    outline: 2px solid #23a6d5;
    transform: scale(1.05);
}

/* Image Styling */
.news-card .news-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-bottom: 2px solid #ee7752;
    transition: transform 0.3s ease;
}

.news-card:hover .news-image {
    transform: scale(1.1);
}

/* News Content */
.news-content {
    padding: 20px;
    background-color: #fff;
    transition: background-color 0.3s ease;
}

.news-card:hover .news-content {
    background-color: #f9f9f9;
}

/* Title inside News Card */
.news-card-title {
    font-size: 1.8rem;
    color: #333;
    margin: 0;
    font-weight: bold;
    line-height: 1.4;
    transition: color 0.3s ease;
}

.news-card-title:hover {
    color: #23a6d5;
}

/* Excerpt inside News Card */
.news-card-excerpt {
    font-size: 1.1rem;
    color: #555;
    margin: 15px 0;
    line-height: 1.6;
    height: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: color 0.3s ease;
}

.news-card-excerpt:hover {
    color: #23a6d5;
}

/* Date inside News Card */
.news-card-date {
    font-size: 1rem;
    color: #888;
    margin-top: 10px;
}

/* Page Effect (for animations) */
.page-effect {
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.8s ease-in-out;
}

.page-effect.appear {
    opacity: 1;
    transform: translateY(0);
}

/* Responsive Design */

/* Large Screens */
@media (max-width: 1200px) {
    .news-title {
        font-size: 2.8rem;
    }

    .news-card-title {
        font-size: 1.6rem;
    }

    .news-card-excerpt {
        font-size: 1rem;
    }

    .news-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* Tablets and Smaller Laptops */
@media (max-width: 768px) {
    .news-title {
        font-size: 2.2rem;
    }

    .news-card-title {
        font-size: 1.5rem;
    }

    .news-card-excerpt {
        font-size: 0.9rem;
    }

    .news-grid {
        grid-template-columns: 1fr;
    }

    .news-card {
        border: 2px solid #23a6d5;
    }
}

/* Mobile Devices */
@media (max-width: 576px) {
    .news-container {
        padding: 15px;
    }

    .news-title {
        font-size: 1.8rem;
    }

    .news-card-title {
        font-size: 1.3rem;
    }

    .news-card-excerpt {
        font-size: 0.8rem;
    }

    .news-card {
        padding: 10px;
    }

    .news-card-date {
        font-size: 0.9rem;
    }

    .news-grid {
        gap: 20px;
    }
}

