/* Page6.css */

/* Video Container */
.video-container {
    width: 100%;
    max-width: 1800px;
    margin: 0 auto;
    padding: 40px;
    font-family: 'Arial', sans-serif;
    border-radius: 15px;
    text-align: center;
    position: relative;
}

/* Video Title */
.video-title {
    font-size: 2.5rem;
    color: #23a6d5;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
}

/* Video Frame */
.video-frame {
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    margin-bottom: 40px;
}

/* Video Player */
.video-player {
    width: 100%;
    height: auto;
    border-radius: 10px;
    outline: none;
}

/* Content Section */
.content-section {
    position: relative;
    color: #fff;
    text-align: center;
    padding: 30px 20px;
    background: rgba(0, 0, 0, 0.6);
    max-width: 800px;
    width: 90%;  /* Ensures the content doesn't exceed screen width */
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease;
    margin: 0 auto; /* Center the content */
}

/* Section Titles */
.section-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 1px;
}

/* Section Text */
.section-text {
    font-size: 1.25rem;
    line-height: 1.8;
    margin-bottom: 15px;
    color: #ddd;
}

/* Discover Section */
.discover-title {
    font-size: 2rem;
    margin-top: 30px;
    font-weight: bold;
    color: #23a6d5;
}

.discover-text {
    font-size: 1.5rem;
    margin-top: 10px;
    color: #fff;
}

/* Hover Effect */
/* .content-section:hover {
    transform: translate(-50%, -50%) scale(1.05);
} */

/* Responsive Design for Smaller Screens */

/* Large Screens */
@media (max-width: 1200px) {
    .video-title {
        font-size: 2rem;
    }

    .content-section {
        max-width: 700px;
        padding: 25px 15px;
    }

    .section-title {
        font-size: 2.2rem;
    }

    .section-text {
        font-size: 1.1rem;
    }

    .discover-title {
        font-size: 1.8rem;
    }

    .discover-text {
        font-size: 1.4rem;
    }
}

/* Medium Screens (Tablets) */
@media (max-width: 992px) {
    .video-title {
        font-size: 1.8rem;
    }

    .content-section {
        max-width: 600px;
        padding: 20px;
    }

    .section-title {
        font-size: 2rem;
    }

    .section-text {
        font-size: 1rem;
    }

    .discover-title {
        font-size: 1.6rem;
    }

    .discover-text {
        font-size: 1.2rem;
    }
}

/* Small Screens (Large Phones and Small Tablets) */
@media (max-width: 768px) {
    .video-container {
        padding: 20px;
    }

    .video-title {
        font-size: 1.6rem;
    }

    .content-section {
        max-width: 500px;
        padding: 15px;
    }

    .section-title {
        font-size: 1.8rem;
    }

    .section-text {
        font-size: 0.9rem;
    }

    .discover-title {
        font-size: 1.4rem;
    }

    .discover-text {
        font-size: 1rem;
    }

    /* Stack video and content vertically for small screens */
    .video-container, .content-section {
        margin-bottom: 20px;
    }

    /* Ensure the video fits the width of the container */
    .video-player {
        max-width: 100%;
        height: auto;
    }
}

/* Extra Small Screens (Mobile Phones) */
@media (max-width: 576px) {
    .video-title {
        font-size: 1.4rem;
    }

    .content-section {
        max-width: 90%;
        padding: 10px;
    }

    .section-title {
        font-size: 1.6rem;
    }

    .section-text {
        font-size: 0.85rem;
    }

    .discover-title {
        font-size: 1.3rem;
    }

    .discover-text {
        font-size: 1rem;
    }

    /* Video player adjustments for mobile */
    .video-player {
        max-width: 100%;
    }
}

/* Extremely Small Devices (Very Small Phones or Portrait Mode) */
@media (max-width: 400px) {
    .video-title {
        font-size: 1.2rem;
    }

    .content-section {
        max-width: 95%;
        padding: 10px;
    }

    .section-title {
        font-size: 1.4rem;
    }

    .section-text {
        font-size: 0.8rem;
    }

    .discover-title {
        font-size: 1.2rem;
    }

    .discover-text {
        font-size: 1rem;
    }

    /* Video player adjustments */
    .video-player {
        max-width: 100%;
    }
}
